import { useState, useEffect } from 'react';
import { GoogleAuthProvider, onAuthStateChanged, signInWithRedirect, getRedirectResult, } from 'firebase/auth';
import { auth } from './firebase';
import { useHistory } from 'react-router-dom';
import { saveUserData } from './firebase';
import { firestore } from 'firebase/firestore';

export function useAuth() {
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // loading状態を追加
  const provider = new GoogleAuthProvider();

  // ログインボタンがクリックされたときの処理
  const login = async () => {
    signInWithRedirect(auth, provider)
  }

  // useEffectフックを使い、認証状態の変更を監視する。
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
      }
      setLoading(false); // ユーザーデータの読み込みが終わったらloading状態をfalseにする
    });

    const saveDataAndRedirect = async () => {
      try {
        // Google認証の結果を取得
        const result = await getRedirectResult(auth);
        if(result !== null){
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          const user = result.user;

          // ユーザーデータをFirestoreに保存
          await saveUserData(user);

          // /chatへリダイレクト
          history.push('/chat');
        }
      } catch (error) {
        console.error(error);
      }
    }

    saveDataAndRedirect();

    // useEffectのクリーンアップ関数として、認証状態リスナーを解除する関数を返す。
    return unsubscribe;
  }, []);
  
  // 追加: setUser, loading状態も返却する
  return { user, setUser, login, loading }; // setUserを返すように追加
}
