import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useAuth } from './services/auth';
import Signup from './pages/Signup';
import Profile from './pages/Profile';
import Chat from './pages/Chat';

function App() {
  const { user, loading } = useAuth(); // Destructure loading state

  // If Firebase is still loading user info, return a loading message
  if (loading) {
    return <div>Loading...</div>; // Replace this with a loading spinner or similar if you want
  }

  return (
    <Router>
      <Switch>
        <Route exact path='/'>
          <Redirect to='/signup' />
        </Route>
        <Route path='/signup'>
          {user ? <Redirect to='/chat' /> : <Signup />}
        </Route>
        <Route path='/profile'>
          {user ? <Profile /> : <Redirect to='/signup' />}
        </Route>
        <Route path='/chat'>
          {user ? <Chat /> : <Redirect to='/signup' />}
        </Route>
        <Route path='*'>
          <Redirect to='/chat' />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
