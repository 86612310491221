import React, { useState,useEffect} from "react";
import {
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  Container,
  Link,
  Avatar,
} from "@mui/material";
import { ref, uploadBytes,getDownloadURL } from "firebase/storage"; 
import { firestorage,saveUserOldGFData } from "../../services/firebase";
import { useHistory } from "react-router-dom";
import { useAuth } from '../../services/auth';
import { getUserData } from "../../services/firebase";

function Profile() {
  // 名前を管理する
  const [name, setName] = useState("");

  // 画像を管理する
  const [image, setImage] = useState(null);

  // エラーメッセージの表示
  const [error, setError] = useState(false);

  // 画面遷移できるようにする
  const history = useHistory();

  // useAuthフックからuserオブジェクトと再設定関数を取得
  const { user, setUser } = useAuth();

  const [profile, setProfile] = useState(null);

  // ユーザーデータを取得してnameとimageのstateを設定する
  useEffect(() => {
    const fetchData = async () => {
      if (user) { // userが存在するかどうかをチェック
        const data = await getUserData(user.uid);
        if (data) {
          setName(data.user_old_girl_friend_name); // 名前を設定
          const response = await fetch(data.user_old_girl_friend_image);
          const blob = await response.blob();
          setImage(blob); // 画像を設定
        }
      }
    };
    fetchData();
  }, [user]);


  // 画像を変更する
  const handleChange = (e) => {
    if(e.target.files[0]){
      setImage(e.target.files[0]);
    }
  };

  // 画像と元カノの名前をFirestorageとFirestoreに送信する
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (image) {
        const imageRef = ref(firestorage, image.name);
        await uploadBytes(imageRef, image);
        
        getDownloadURL(imageRef).then(async (url) => {
          // Firestoreに元カノの写真と名前を保存
          await saveUserOldGFData(user.uid, {
            user_old_girl_friend_image: url,
            user_old_girl_friend_name: name,
          });          
          // userオブジェクトを再設定
          setUser({
            ...user,
            user_old_girl_friend_name: name,
            user_old_girl_friend_image: url,
          });
        });
      }
    } catch (err) {
      console.log(err.meesage);
      setError(true);
    }
    history.push("/chat");
  };

  

  return (
    <Container maxWidth="sm">
    <Paper style={{ margin: 4, padding: 4 }}>
      <Typography align="center">あなたの元カノプロフィール</Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate style={{ marginTop: 4 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
        <Avatar
        src={image ? URL.createObjectURL(image) : profile ? profile.user_old_girl_friend_image : ""}
        alt=""
        />
          <Box>
            <input
              id="image"
              type="file"
              accept="image/png, image/jpeg"
              onChange={handleChange}
              style={{ display: "none" }}
            />
            <label htmlFor="image">
              <Button variant="contained" color="primary" component="span" className="choose-image-button">
                画像を選択
              </Button>
            </label>
          </Box>
        </Box>
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="ユーザー名"
          name="name"
          autoComplete="name"
          autoFocus
          value={name ? name : profile ? profile.user_old_girl_friend_name : ""}
          onChange={(e) => setName(e.target.value)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          style={{ marginTop: 3, marginBottom: 2 }}
        >
          保存してチャット画面へ
        </Button>
      </Box>
    </Paper>
  </Container>
  );
}
export default Profile;