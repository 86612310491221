import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { auth, saveUserData } from "../../services/firebase";
import { onAuthStateChanged, signInWithRedirect, getRedirectResult, GoogleAuthProvider, signOut } from "firebase/auth";
import './index.css';

function Signup () {
  // Google認証のためのproviderを作成
  const provider = new GoogleAuthProvider();
  // historyを取得（画面遷移のため）
  const history = useHistory();

  // ログインボタンがクリックされたときの処理
  const clickLogin = async () => {
    signInWithRedirect(auth, provider)
  }

  // コンポーネントがマウントされたときの処理
  useEffect(() => {
    const saveDataAndRedirect = async () => {
      try {
        // Google認証の結果を取得
        const result = await getRedirectResult(auth);
        if(result !== null){
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          const user = result.user;

          // ユーザーデータをFirestoreに保存
          await saveUserData(user);
          console.log("ユーザーデータの保存が完了しました。");

          // /chatへリダイレクト
          history.push('/chat');
        }
      } catch (error) {
        console.error(error);
      }
    }

    saveDataAndRedirect();
  },[history]);

  // ログイン状態の確認
  const checkLogin = () => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log(user.uid, user.email);
      } else {
        console.log("signed out");
      }
    });
  }

  checkLogin();

  return (
    <div className="signup-page">
      <div className="signup-container">
        <h1>元カノチャット</h1>
        <img 
          src="/google_signup_logo.png" 
          alt="Signup with Google" 
          onClick={clickLogin}
          style={{cursor: 'pointer'}}
          width={200}
        />
      </div>
    </div>
  );
};

export default Signup;
