import { initializeApp, getApps } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  serverTimestamp,
  onSnapshot,
  query,
  orderBy,
  initializeFirestore,
  doc, 
  getDoc,
  setDoc,
} from "firebase/firestore";
import { GoogleAuthProvider,getAuth } from "firebase/auth";
import { getFunctions} from "firebase/functions";
import { getStorage,ref } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApps()[0];
}

initializeFirestore(app, {
  ignoreUndefinedProperties: true,
});
const firestore = getFirestore(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const firestorage = getStorage(app);
const provider = new GoogleAuthProvider();
const auth = getAuth(app);

// Firebaseにメッセージを保存
async function addMessage(userUid, { message, sender, direction }) {
  await addDoc(collection(db, "users", userUid, "messages"), {
    direction: direction || "unknown",
    message: message || "unknown",
    sender: sender || "unknown",
    timestamp: serverTimestamp(),
    user_id: userUid,  // この行を追加
  });
}

// Firestoreからユーザーのプロフィールを取得
async function getUserData(user) {
  const docRef = doc(db, 'users', user);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
}

// ユーザーデータをFirestoreに保存
const saveUserData = async (user) => {
  const docRef = doc(firestore, "users", user.uid);
  await setDoc(docRef, {
    user_id: user.uid,
    timestamp: serverTimestamp(),  // サーバーのタイムスタンプ
    user_old_girl_friend_image: 'https://firebasestorage.googleapis.com/v0/b/old-girl-friend-chat.appspot.com/o/motokanon.png?alt=media&token=b0a5a53e-ee36-4d7b-927e-fecadf0bad87',  // 元カノの画像, デフォルトはnull
    user_old_girl_friend_name: 'もとかのん',  // 元カノの名前
  });
};

// 元カノの画像と名前をFirestoreに保存
const saveUserOldGFData = async (userId, data) => {
  const docRef = doc(firestore, "users", userId);
  await setDoc(docRef, {
    ...data,
    user_id: userId,
    timestamp: serverTimestamp(),  // サーバーのタイムスタンプ
  }, { merge: true });
};

export { app, functions,firestorage,ref,db,provider, auth,addMessage, getUserData,saveUserData,saveUserOldGFData};