import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Avatar,
  TypingIndicator,
} from '@chatscope/chat-ui-kit-react';
import { db, addMessage, getUserData, getUserMessages } from '../../services/firebase';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { auth } from '../../services/firebase';
import { signOut } from "firebase/auth";
import { useAuth } from '../../services/auth';
import { useHistory } from 'react-router-dom';
import './index.css';

function Chat() {
  // メッセージを保持する
  const [messages, setMessages] = useState([]);

  // メッセージを送信したら、返答が返ってくるまでメッセージを送信できないようにする
  const [isTyping, setIsTyping] = useState(false);

  const history = useHistory();

  const { user } = useAuth();

  const [message, setMessage] = useState('');

  const [userOldGFImage, setUserOldGFImage] = useState(null);

  const [userOldGFName, setUserOldGFName] = useState(null);

  // Firebaseからデータを取得し、messagesにセットする
  useEffect(() => {
    if (!user) {
      return;
    }

    // Firestoreのユーザーのmessagesサブコレクションへの参照を取得
    const messagesCol = collection(db, "users", user.uid, "messages");
    const q = query(messagesCol, orderBy("timestamp"));
    
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newMessages = snapshot.docs.map((doc) => ({
        id: doc.id,
        sender: doc.data().sender,
        message: doc.data().message,
        direction: doc.data().sender === "user" ? "outgoing" : "incoming",
      }));

      setMessages(newMessages);
    });

    getUserData(user.uid).then((data) => {
      setUserOldGFImage(data.user_old_girl_friend_image);
      setUserOldGFName(data.user_old_girl_friend_name);
    });

    return () => unsubscribe();

  }, [user, history]);

  // メッセージをFirestoreに保存し、ローカルのメッセージリストを更新
  async function handleSend(text) {
    
    const userMessage = {
      direction: "incoming",
      message: text,
      sender: "user",
    };

    // 最新のメッセージを含む配列を生成
    const newMessages = [...messages, userMessage];

    setMessages(newMessages);
    await addMessage(user.uid, { // ユーザーのUIDを渡す
      direction: userMessage.direction,
      message: userMessage.message,
      sender: userMessage.sender,
    });
    setIsTyping(true);

    // processMessageToChatGPTに最新のメッセージを含む配列を渡す
    await processMessageToChatGPT(newMessages);
  }

  // チャットメッセージをChatGPT形式に変換
  async function processMessageToChatGPT(chatMessages) {
    // 直近の3往復（6メッセージ）だけを取得
    const recentMessages = chatMessages.slice(Math.max(chatMessages.length - 6, 0));
    // ロールを分ける
    let apiMessages = recentMessages.map((messageObject) => {
      let role = "";
      if (messageObject.sender === "chatgpt") {
        role = "assistant";
      } else {
        role = "user";
      }

      // 各メッセージが200文字を超えている場合は、最初の200文字だけを取得
      let content = messageObject.message;
      if (content.length > 200) {
        content = content.slice(0, 200);
      }
      return { role: role, content: messageObject.message };
    });
  
    // チャットセッションを開始する
    const systemMessage = {
      role: "system",
      content: `
      あなたはユーザーが昔付き合っていた彼女として振る舞います。
      とにかく相手を肯定してください
      `,
    };
  
    // 使用するChatGPTのモデルを定義
    const apiRequestBody = {
      "model": "gpt-3.5-turbo",
      "messages": [
        systemMessage,
        ...apiMessages
      ],
    }
  
    // APIにリクエストを送信
    await fetch("https://api.openai.com/v1/chat/completions", {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(apiRequestBody)
    }).then((data) => {
      return data.json();
    }).then(async (data) => {
      const gptResponse = {
        message: data.choices[0].message.content,
        sender: "chatgpt",
        direction: "outgoing"  // ChatGPTからのメッセージは"outgoing"とします
      };
      setMessages([...chatMessages, gptResponse]);
  
      // FirebaseにChatGPTからのメッセージを保存
      await addMessage(user.uid, {
        direction: gptResponse.direction,
        message: gptResponse.message,
        sender: gptResponse.sender,
      });
  
      setIsTyping(false);
    });
  }

  const clickLogout = async () => {
    try {
      await signOut(auth);
      console.log("ログアウトしました"); 
    } catch (error) {
      console.log(`ログアウト時にエラーが発生しました (${error})`);
    }
  }
  
  return (
    <div>
      {/* リリース時にはコメントアウト */}
      <button onClick={clickLogout}>Logout</button>
      <div className="chat-container">
        <Link to="/profile" style={{ textDecoration: "none" }} className="old-girl-friend">
          <Avatar src={userOldGFImage} name="ChatGPT" className="avatar-space" />
          <p>{userOldGFName}</p>
        </Link>
        <MainContainer>
          <ChatContainer>
            {/* メッセージを繰り返し表示する */}
            <MessageList
                          scrollBehavior="smooth" 
                          typingIndicator={isTyping ? <TypingIndicator content="元カノが入力中" /> : null}
            >
              {messages.map((message, i) => {
                return (
                  <Message
                    key={i}
                    model={{
                      message: message.message,
                      sender: message.sender,
                      direction: message.direction,
                    }}
                  />
                );
              })}
            </MessageList>
            <MessageInput
              placeholder="メッセージを入力"
              attachButton={false}
              onSend={handleSend}
            />
          </ChatContainer>
        </MainContainer>
      </div>
    </div>
  );
}

export default Chat;
